/*
 |-----------------------------------------------------------------------------
 | pages/404/index.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { GetStaticProps, NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { TinaMarkdown } from 'tinacms/dist/rich-text';
import classNames from 'classnames';

import { fallback } from '@/utils/fallback';
import { richText } from '@/utils/richText';
import { transform } from '@/utils/transform';

import Picture from '@/atoms/Picture';

import styles from './404.module.scss';

interface IProps {
	data: object;
}

const Custom404: NextPage<IProps> = ({ data }) => {
	const { data: organisation } = get(data, 'getOrganisationDocument');
	const { data: page } = get(data, 'getPageDocument');

	const transforms = {
		default: transform(
			get(data, ['getPageDocument', 'data', 'image', 'src']),
			'f_auto,w_1280'
		),
		tabletL: transform(
			get(data, ['getPageDocument', 'data', 'image', 'src']),
			'f_auto,w_1024'
		),
		tabletP: transform(
			get(data, ['getPageDocument', 'data', 'image', 'src']),
			'f_auto,c_fill,h_1024,w_768'
		),
		mobileL: transform(
			get(data, ['getPageDocument', 'data', 'image', 'src']),
			'f_auto,c_fill,h_736,w_414'
		),
		mobileS: transform(
			get(data, ['getPageDocument', 'data', 'image', 'src']),
			'f_auto,c_fill,h_667,w_375'
		),
	};

	return (
		<>
			<Helmet>
				<body
					className="custom404"
					itemScope
					itemType="http://schema.org/WebPage"
				/>

				<link
					rel="preload"
					href={get(transforms, 'default')}
					as="image"
					imageSrcSet={`
						${get(transforms, 'default')} 1200w,
						${get(transforms, 'tabletL')} 1024w,
						${get(transforms, 'tabletP')} 768w,
						${get(transforms, 'mobileL')} 414w,
						${get(transforms, 'mobileS')} 375w
					`}
				/>
			</Helmet>

			<DefaultSeo
				title={fallback(
					get(page, ['meta', 'title']),
					get(page, 'title')
				)}
				titleTemplate={`%s | ${get(organisation, 'name')}`}
				dangerouslySetAllPagesToNoIndex
				dangerouslySetAllPagesToNoFollow
			/>

			<main
				className={get(styles, 'content')}
				id="main-content"
				itemProp="mainContentOfPage"
			>
				{get(data, ['getPageDocument', 'data', 'image']) && (
					<Picture
						alt={get(data, [
							'getPageDocument',
							'data',
							'image',
							'alt',
						])}
						className="picture"
						isBackground={true}
						lazyLoad={false}
						schema={null}
						src={transforms}
					/>
				)}
				<div className={get(styles, 'foreground')}>
					<section
						className={classNames(get(styles, 'body'), 'u-spacing')}
					>
						<header className={get(styles, 'header')}>
							<div
								className={get(styles, 'logo')}
								itemScope
								itemType="http://schema.org/Organization"
							>
								<svg
									aria-label={get(organisation, 'name')}
									role="img"
								>
									<use xlinkHref="/imgs/lgo_sprite.svg#brand"></use>
								</svg>
							</div>

							<h1>{get(page, 'title')}</h1>
						</header>

						<div
							className={classNames(
								get(styles, 'main'),
								'u-spacing'
							)}
						>
							<TinaMarkdown content={get(page, 'text')} />
						</div>
					</section>
				</div>
			</main>
		</>
	);
};

export const getStaticProps: GetStaticProps = async () => {
	const data = {
		getPageDocument: {
			data: {
				image: {
					alt: '',
					src: `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/bgr_404.jpg`,
				},
				meta: {
					title: 'Page not found',
				},
				text: richText([
					{
						type: 'p',
						children: [
							{
								type: 'text',
								text: 'Our apologies but the page you were looking for could not be found; it may have been moved or deleted. To try and find what you were looking for use the search below or the menu above.',
							},
						],
					},
				]),
				title: 'Page not found',
			},
		},
		getOrganisationDocument: {
			data: {
				name: 'Strapless',
			},
		},
	};

	return { props: { data } };
};

export default Custom404;

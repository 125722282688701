/*
 |-----------------------------------------------------------------------------
 | components/atoms/Picture/Picture.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IPicture from './types';

import styles from './Picture.module.scss';

const variants = {
	duotone: 'duotone',
	greyscale: 'greyscale',
	overlay: 'overlay',
	zoom: 'zoom',
};

const Picture: React.FC<IPicture.IProps> = ({
	alt,
	className,
	isBackground,
	lazyLoad,
	schema,
	src,
	variant,
}) => {
	const classes = classNames(
		get(styles, variants[variant]),
		{
			[get(styles, 'background')]: isBackground,
		},
		className
	);

	// ray('Debug atom Picture:', {
	// 	alt: alt,
	// 	className: className,
	// 	isBackground: isBackground,
	// 	lazyLoad: lazyLoad,
	// 	schema: schema,
	// 	src: src,
	// 	variant: variant,
	// }).red();

	return (
		<picture className={classes} itemProp={schema}>
			{lazyLoad ? (
				<>
					{get(src, 'mobileS') && (
						<source
							media="(max-width: 375px)"
							data-srcset={get(src, 'mobileS')}
						/>
					)}
					{get(src, 'mobileL') && (
						<source
							media="(max-width: 414px)"
							data-srcset={get(src, 'mobileL')}
						/>
					)}
					{get(src, 'tabletP') && (
						<source
							media="(max-width: 768px)"
							data-srcset={get(src, 'tabletP')}
						/>
					)}
					{get(src, 'tabletL') && (
						<source
							media="(max-width: 1024px)"
							data-srcset={get(src, 'tabletL')}
						/>
					)}
					<img
						className={`${get(styles, 'image')} lazyload`}
						alt={alt || ''}
						data-sizes="auto"
						data-src={get(src, 'default')}
						src={get(src, 'default')}
					/>
				</>
			) : (
				<>
					{get(src, 'mobileS') && (
						<source
							media="(max-width: 375px)"
							srcSet={get(src, 'mobileS')}
						/>
					)}
					{get(src, 'mobileL') && (
						<source
							media="(max-width: 414px)"
							srcSet={get(src, 'mobileL')}
						/>
					)}
					{get(src, 'tabletP') && (
						<source
							media="(max-width: 768px)"
							srcSet={get(src, 'tabletP')}
						/>
					)}
					{get(src, 'tabletL') && (
						<source
							media="(max-width: 1024px)"
							srcSet={get(src, 'tabletL')}
						/>
					)}
					<img
						className={get(styles, 'image')}
						alt={alt || ''}
						src={get(src, 'default')}
					/>
				</>
			)}
		</picture>
	);
};

export default Picture;
